import { FieldErrorComponent } from "@asantech/common/react/FieldErrorComponent";
import { Trans } from "@lingui/macro";
import { requestReceipt } from "api/visits";
import { emailValidationPattern, requiredRule } from "common/formUtils";
import { useHandleFinishedFn } from "common/hooks/useHandleFinishedFn";
import { isTerminal } from "common/utils";
import { Label } from "components/Label";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Button } from "./Button";
import { WidthContainer } from "./Container";
import { useIdleTimeout } from "./IdleTimeoutProvider";
import { IdleTimerBar } from "./IdleTimerBar";
import { StatusIcon } from "./StatusIcon";
import { TextField } from "./TextField";
import { VirtualKeyboard } from "./VirtualKeyboard";

type FormData = { email: string };

type Props = { paymentId: string };

export const ReceiptRequest = ({ paymentId }: Props) => {
  const { restartIdleTimeout } = useIdleTimeout();
  const handleFinished = useHandleFinishedFn();

  const {
    formState: { errors, isSubmitting, isSubmitSuccessful },
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = useCallback(
    async ({ email }: FormData) => {
      try {
        await requestReceipt({ paymentId, email });
        restartIdleTimeout(5);
      } catch (e) {
        if (e instanceof Error) toast.error(e.message);
        reset();
        throw e;
      }
    },
    [paymentId, restartIdleTimeout, reset]
  );

  const email = watch("email");
  const setEmail = useCallback(
    (value: string) => {
      setValue("email", value);
    },
    [setValue]
  );

  return (
    <>
      {isSubmitSuccessful && (
        <>
          <StatusIcon />
          <WidthContainer>
            <Status>
              <Trans>Receipt sent</Trans>
            </Status>
            <Button variant="secondary" onClick={handleFinished}>
              <Trans>Okay</Trans>
              <IdleTimerBar />
            </Button>
          </WidthContainer>
        </>
      )}
      {!isSubmitSuccessful && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <WidthContainerNarrow>
            <Label>
              <Trans>Enter your email address to get a receipt:</Trans>
            </Label>
            <TextFieldWrapper disabled={isTerminal}>
              <TextField
                register={register}
                name="email"
                type="email"
                variant="secondary"
                errors={errors.email}
                placeholder={"Email"}
                registerRules={{
                  ...emailValidationPattern(),
                  ...requiredRule(),
                }}
              />
            </TextFieldWrapper>
            <FieldErrorComponent error={errors.email} />
          </WidthContainerNarrow>
          {isTerminal && (
            <KeyboardWrapper>
              <VirtualKeyboard value={email} setValue={setEmail} email={true} />
            </KeyboardWrapper>
          )}
          <WidthContainer>
            <Buttons>
              <Button type="submit" loading={isSubmitting}>
                <Trans>Send receipt</Trans>
              </Button>
            </Buttons>
          </WidthContainer>
        </form>
      )}
    </>
  );
};

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 40px 0;
`;

const KeyboardWrapper = styled.div`
  margin-top: 40px;
`;

const TextFieldWrapper = styled.fieldset`
  margin: 0 0 0 0;
  padding: 0;
  border: 0;

  & input {
    font-size: 30px;
    height: 60px;
    text-align: center;
  }
`;

const WidthContainerNarrow = styled(WidthContainer)`
  max-width: 600px;
`;

const Status = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  letter-spacing: 0.4px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 20px;
  line-height: normal;
`;
