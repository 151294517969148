import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Pages } from "common/pages";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Button";

import { FieldErrorComponent } from "@asantech/common/react/FieldErrorComponent";
import { Trans } from "@lingui/macro";
import { emailValidationPattern } from "common/formUtils";
import { getUrlPaymentId, PAYMENT_ID_SEARCH_PARAM } from "common/stripe";
import { Euros } from "components/Euros";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { TextField } from "../../components/TextField";
import { useVisits } from "../../store/useVisits";

type FormData = {
  email: string;
};
export default function PaymentForm() {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { setPaymentError, totalPrice } = useVisits();

  const {
    formState: { errors },
    register,
    getValues,
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    try {
      const search = new URLSearchParams({
        [PAYMENT_ID_SEARCH_PARAM]: getUrlPaymentId() || "",
      }).toString();
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          receipt_email: getValues("email"),
          return_url:
            window.location.origin + Pages.PaymentComplete + "?" + search,
        },
      });
      if (error) {
        if (error?.type === "validation_error") return;
        setPaymentError(error?.message);
        history.push(Pages.PaymentFailure);
      }
    } catch (error) {
      console.error(error);
      history.push(Pages.PaymentFailure);
    } finally {
      setIsLoading(false);
    }
  }, [elements, getValues, history, setPaymentError, stripe]);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <h2>
        <Trans>Payment</Trans>
      </h2>
      <StyledTrans>
        <Trans>Email for receipt</Trans>
      </StyledTrans>
      <TextFieldWrapper>
        <TextField
          register={register}
          name="email"
          type="email"
          variant="secondary"
          errors={errors.email}
          placeholder={"Email"}
          registerRules={emailValidationPattern()}
        />
      </TextFieldWrapper>
      <FieldErrorComponent error={errors.email} />
      <PaymentFormWrapper>
        <PaymentElement />
      </PaymentFormWrapper>
      <Button type="submit" loading={isLoading}>
        <Trans>Pay now</Trans> <Euros cents={totalPrice} />
      </Button>
    </StyledForm>
  );
}
const StyledTrans = styled.div`
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: var(--text-primary);
`;

const StyledForm = styled.form`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding: 1px;
`;

const PaymentFormWrapper = styled.div`
  margin: 20px 0 40px 0;
`;

const TextFieldWrapper = styled.div`
  & input {
    padding: 0.75rem;
    border-radius: 5px;
    color: var(--text-secondary);
    height: 44px;
    border: solid 1px;
    border-color: var(--text-secondary);
  }
`;
